<template>
  <div>
    <!-- Hero -->
    <div class="bg-body-light">
      <div class="content content-full">
        <div
          class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center py-2 text-center text-sm-left"
        >
          <div class="flex-sm-fill">
            <h1 class="h3 font-w500 mb-2">FerreProd Systems</h1>
            <h2 class="h6 font-w500 text-muted mb-0">
              Bienvenido<a class="font-w600" href="javascript:void(0)"> {{ GetUserData().name}}</a
              >, buenos dias.
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- END Hero -->

    <!-- Page Content -->
    <div class="content">
      <!-- Stats -->
      <b-row>
        <b-col cols="6" md="3" lg="6" xl="3">
          <base-block
            tag="a"
            rounded
            link-pop
            content-class="d-flex py-4"
            href="javascript:void(0)"
          >
            <div class="flex-grow-1">
              <div class="font-size-sm font-w600 text-uppercase text-muted">
                Usuarios
              </div>
              <div class="font-size-h3">6</div>
            </div>
            <div class="d-flex ml-2">
              <div class="flex-grow-1 px-1 bg-success-light rounded-lg"></div>
            </div>
          </base-block>
        </b-col>
        <b-col cols="6" md="3" lg="6" xl="3">
          <base-block
            tag="a"
            rounded
            link-pop
            content-class="d-flex py-4"
            href="javascript:void(0)"
          >
            <div class="flex-grow-1">
              <div class="font-size-sm font-w600 text-uppercase text-muted">
                Ventas
              </div>
              <div class="font-size-h3">150</div>
            </div>
            <div class="d-flex ml-2">
              <div class="flex-grow-1 px-1 bg-danger-light rounded-lg"></div>
            </div>
          </base-block>
        </b-col>
        <b-col cols="6" md="3" lg="6" xl="3">
          <base-block
            tag="a"
            rounded
            link-pop
            content-class="d-flex py-4"
            href="javascript:void(0)"
          >
            <div class="flex-grow-1">
              <div class="font-size-sm font-w600 text-uppercase text-muted">
                Ganancias
              </div>
              <div class="font-size-h3">$3,200</div>
            </div>
            <div class="d-flex ml-2">
              <div class="flex-grow-1 px-1 bg-success-light rounded-lg"></div>
            </div>
          </base-block>
        </b-col>
        <b-col cols="6" md="3" lg="6" xl="3">
          <base-block
            tag="a"
            rounded
            link-pop
            content-class="d-flex py-4"
            href="javascript:void(0)"
          >
            <div class="flex-grow-1">
              <div class="font-size-sm font-w600 text-uppercase text-muted">
                Media de Ventas
              </div>
              <div class="font-size-h3">$21</div>
            </div>
            <div class="d-flex ml-2">
              <div class="flex-grow-1 px-1 bg-danger-light rounded-lg"></div>
            </div>
          </base-block>
        </b-col>
      </b-row>
      <!-- END Stats -->

      <!-- Dashboard Charts -->
      <b-row>
        <b-col lg="6">
          <!-- Earnings Chart -->
          <base-block rounded title="Ganancias en $" header-bg>
            <template #options>
              <button type="button" class="btn-block-option">
                <i class="si si-settings"></i>
              </button>
            </template>
            <template #content>
              <div class="block-content p-0">
                <div class="pt-3">
                  <chartjs-line
                    :chart-data="chartjsEarningsData"
                    :options="chartjsEarningsOptions"
                    :styles="chartjsStyles"
                  ></chartjs-line>
                </div>
              </div>
            </template>
          </base-block>
          <!-- END Earnings Chart -->
        </b-col>
        <b-col lg="6">
          <!-- Sales Chart -->
          <base-block rounded title="Ventas" header-bg>
            <template #options>
              <button type="button" class="btn-block-option">
                <i class="si si-settings"></i>
              </button>
            </template>
            <template #content>
              <div class="block-content p-0">
                <div class="pt-3">
                  <chartjs-line
                    :chart-data="chartjsSalesData"
                    :options="chartjsSalesOptions"
                    :styles="chartjsStyles"
                  ></chartjs-line>
                </div>
              </div>
            </template>
          </base-block>
          <!-- END Sales Chart -->
        </b-col>
      </b-row>
      <!-- END Dashboard Charts -->

      <!-- Customers and Latest Orders -->
      <b-row class="row-deck">
        <!-- Latest Customers -->
        <b-col xl="6">
          <base-block rounded title="Ultimos Clientes" header-bg content-full>
            <template #options>
              <button type="button" class="btn-block-option">
                <i class="si si-settings"></i>
              </button>
            </template>
            <b-table-simple
              striped
              hover
              borderless
              class="table-vcenter font-size-sm mb-0"
            >
              <b-thead>
                <b-tr>
                  <b-th class="font-w700" style="width: 80px">ID</b-th>
                  <b-th class="font-w700">Cliente</b-th>
                  <b-th
                    class="d-none d-sm-table-cell font-w700 text-center"
                    style="width: 80px"
                    >Ordenes</b-th
                  >
                  <b-th
                    class="font-w700 text-center"
                    style="width: 60px"
                  ></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="customer in latestCustomers" :key="customer.id">
                  <b-td class="font-w600">
                    {{ customer.id }}
                  </b-td>
                  <b-td>
                    <a class="link-fx font-w600" href="javascript:void(0)">{{
                      customer.name
                    }}</a>
                  </b-td>
                  <b-td class="d-none d-sm-table-cell text-center">
                    <a class="link-fx font-w600" href="javascript:void(0)">{{
                      customer.orders
                    }}</a>
                  </b-td>
                  <b-td class="text-center">
                    <a
                      href="javascript:void(0)"
                      v-b-tooltip.hover.nofade.left="'Edit'"
                    >
                      <i class="fa fa-fw fa-pencil-alt"></i>
                    </a>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </base-block>
        </b-col>
        <!-- END Latest Customers -->

        <!-- Latest Orders -->
        <b-col xl="6">
          <base-block rounded title="Ultimas ordenes" header-bg content-full>
            <template #options>
              <button type="button" class="btn-block-option">
                <i class="si si-settings"></i>
              </button>
            </template>
            <b-table-simple
              striped
              hover
              borderless
              class="table-vcenter font-size-sm mb-0"
            >
              <b-thead>
                <b-tr>
                  <b-th class="font-w700">ID</b-th>
                  <b-th class="d-none d-sm-table-cell font-w700">Fecha</b-th>
                  <b-th class="font-w700">Estatus</b-th>
                  <b-th
                    class="d-none d-sm-table-cell font-w700 text-right"
                    style="width: 120px"
                    >Total $</b-th
                  >
                  <b-th
                    class="font-w700 text-center"
                    style="width: 60px"
                  ></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="order in latestOrders" :key="order.id">
                  <b-td>
                    <a class="font-w600" href="javascript:void(0)"
                      >#{{ order.id }}</a
                    >
                  </b-td>
                  <b-td class="d-none d-sm-table-cell">
                    {{ order.date }}
                  </b-td>
                  <b-td>
                    <span
                      class="font-w600"
                      :class="`text-${order.stateVariant}`"
                      >{{ order.state }}</span
                    >
                  </b-td>
                  <b-td class="d-none d-sm-table-cell text-right">
                    {{ order.total }}
                  </b-td>
                  <b-td class="text-center">
                    <a
                      href="javascript:void(0)"
                      v-b-tooltip.hover.nofade.left="'Manage'"
                    >
                      <i class="fa fa-fw fa-pencil-alt"></i>
                    </a>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </base-block>
        </b-col>
        <!-- END Latest Orders -->
      </b-row>
      <!-- END Customers and Latest Orders -->
    </div>
    <!-- END Page Content -->
  </div>
</template>

<script>
// Chart.js, https://www.chartjs.org
import Chart from "chart.js";

// Line Chart component using Vue Chart.js, for more info and examples you can check out https://github.com/apertureless/vue-chartjs
import ChartjsLine from "@/components/Chartjs/Line";

export default {
  components: {
    ChartjsLine,
  },
  data() {
    return {
      chartjsStyles: {
        position: "relative",
        height: "344px",
      },
      chartjsEarningsOptions: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMax: 3000,
              },
            },
          ],
        },
        tooltips: {
          intersect: false,
          callbacks: {
            label: (tooltipItems) => {
              return " $" + tooltipItems.yLabel;
            },
          },
        },
      },
      chartjsSalesOptions: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMax: 260,
              },
            },
          ],
        },
        tooltips: {
          intersect: false,
          callbacks: {
            label: (tooltipItems) => {
              return " " + tooltipItems.yLabel + " Sales";
            },
          },
        },
      },
      chartjsEarningsData: {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
        datasets: [
          {
            label: "This Year",
            fill: true,
            backgroundColor: "rgba(132, 94, 247, .3)",
            borderColor: "transparent",
            pointBackgroundColor: "rgba(132, 94, 247, 1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(132, 94, 247, 1)",
            data: [
              2150, 1350, 1560, 980, 1260, 1720, 1115, 1690, 1870, 2420, 2100,
              2730,
            ],
          },
          {
            label: "Last Year",
            fill: true,
            backgroundColor: "rgba(233, 236, 239, 1)",
            borderColor: "transparent",
            pointBackgroundColor: "rgba(233, 236, 239, 1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(233, 236, 239, 1)",
            data: [
              2200, 1700, 1100, 1900, 1680, 2560, 1340, 1450, 2000, 2500, 1550,
              1880,
            ],
          },
        ],
      },
      chartjsSalesData: {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
        datasets: [
          {
            label: "This Year",
            fill: true,
            backgroundColor: "rgba(34, 184, 207, .3)",
            borderColor: "transparent",
            pointBackgroundColor: "rgba(34, 184, 207, 1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(34, 184, 207, 1)",
            data: [175, 120, 169, 82, 135, 169, 132, 130, 192, 230, 215, 260],
          },
          {
            label: "Last Year",
            fill: true,
            backgroundColor: "rgba(233, 236, 239, 1)",
            borderColor: "transparent",
            pointBackgroundColor: "rgba(233, 236, 239, 1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(233, 236, 239, 1)",
            data: [220, 170, 110, 215, 168, 227, 154, 135, 210, 240, 145, 178],
          },
        ],
      },
      latestCustomers: [
        {
          id: 1521,
          avatar: "avatar11",
          name: "ROGIMAQ",
          orders: 5,
        },
        {
          id: 1520,
          avatar: "avatar1",
          name: "MAPRECOR",
          orders: 7,
        },
        {
          id: 1519,
          avatar: "avatar5",
          name: "MATERIALIZA",
          orders: 12,
        },
        {
          id: 1518,
          avatar: "avatar12",
          name: "ITECH",
          orders: 19,
        },
        {
          id: 1517,
          avatar: "avatar3",
          name: "SDIMSA",
          orders: 2,
        },
      ],
      latestOrders: [
        {
          id: 7835,
          date: "19/01/2022",
          state: "Pendiente..",
          stateVariant: "warning",
          total: "$999,99",
        },
        {
          id: 7834,
          date: "20/01/2022",
          state: "Pendiente..",
          stateVariant: "warning",
          total: "$2.299,00",
        },
        {
          id: 7833,
          date: "20/01/2022",
          state: "Confirmado",
          stateVariant: "success",
          total: "$1200,00",
        },
      ],
    };
  },
  methods: {
    GetUserData() {
      let auth = JSON.parse(localStorage.autentication);
      return auth.user;
    },
  },
  created() {
    // Set Chart.js configuration
    Chart.defaults.global.defaultFontColor = "#495057";
    Chart.defaults.global.defaultFontStyle = "600";
    Chart.defaults.scale.gridLines.color = "transparent";
    Chart.defaults.scale.gridLines.zeroLineColor = "transparent";
    Chart.defaults.scale.display = false;
    Chart.defaults.scale.ticks.beginAtZero = true;
    Chart.defaults.global.elements.line.borderWidth = 0;
    Chart.defaults.global.elements.point.radius = 0;
    Chart.defaults.global.elements.point.hoverRadius = 0;
    Chart.defaults.global.tooltips.cornerRadius = 3;
    Chart.defaults.global.legend.labels.boxWidth = 12;
  },
};
</script>
